const path = require('path');

export default {
    props: {
        settings: {type: Object}
    },
    methods: {
        buildAssetPath(src, thumb = null) {

            if(!src)
            {
                return '';
            }

            // fix paths
            src = src.replaceAll('\\', '/');

            // filename
            let filename = path.basename(src);

            let prefix = '';

            //prefix = 'https://fire.showroom.astormuellergroup.com/resources/public/dtc/media/showroom_remote';
            prefix = 'https://am-b2b.avelar.ch/resources/public/dtc/media'

            if(null !== thumb)
            {
                filename = 'thumb/thumb_width' + thumb + '_height' + thumb + '_' + filename;
            }

            return `${prefix}/${filename}`;
        },
    }
}